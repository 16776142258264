import store from "@/store";

const verificarFechaNoSeaFeriado = (fecha) => {
  const feriados = store.state.feriados;
  const dia = fecha.date();
  const mes = fecha.month() + 1; // sumamos +1 porque moment retorna un valor entre 0 y 11
  const anio = fecha.year();

  return !feriados.some((feriado) => (feriado.date() === dia && feriado.month() + 1 === mes && feriado.year() === anio));
}


const verificarFechaNoSeaAsueto = (fecha) => {
  const asuetos = store.state.asuetos;
  const dia = fecha.date();
  const mes = fecha.month() + 1; // sumamos +1 porque moment retorna un valor entre 0 y 11

  return !asuetos.some((asueto) => asueto.dia === dia && asueto.mes === mes);
}

const verificarFechaSeaDiaLaborable = (fecha) => {
  const diaDeLaSemana = fecha.toDate().getUTCDay();

    // 0 => Domingo, 6 => Sabado
    return ![0, 6].includes(diaDeLaSemana);
}

export const obtenerNumeroDias = (fecha, fechaFin, contador = 0) => {
  if (fecha.isBefore(fechaFin)) {
    const esDiaLaborable = (
      verificarFechaSeaDiaLaborable(fecha) && 
      verificarFechaNoSeaAsueto(fecha) && 
      verificarFechaNoSeaFeriado(fecha)
    );

    return obtenerNumeroDias(
      fecha.clone().add(1, 'day').startOf('day'),
      fechaFin,
      esDiaLaborable ? contador + 1 : contador,
    );
  }

  return contador;
}


export const obtenerFechasPermitidas = (fecha, numeroDias, rango = []) => {
  if (!(rango.length < numeroDias)) return rango;

  const esDiaLaborable = verificarFechaSeaDiaLaborable(fecha);
  const noEsAsueto = verificarFechaNoSeaAsueto(fecha);
  const noEsFeriado = verificarFechaNoSeaFeriado(fecha);

  if (esDiaLaborable && noEsAsueto && noEsFeriado) {
    rango.push(fecha);
  }

  return obtenerFechasPermitidas(fecha.clone().add(1, 'day'), numeroDias, rango);
};

export const generarHoras = (hora, horas = []) => {
  if (hora < 24) {
    horas.push(hora);
    return generarHoras(hora + 1, horas);
  }

  return horas;
};

export const generarMinutos = (minuto, minutos = []) => {
  if (minuto < 60) {
    minutos.push(minuto);
    return generarMinutos(minuto + 1, minutos);
  }

  return minutos;
};