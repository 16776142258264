<template>
  <section class="ma-8"  style="padding-bottom: 400px;">
    <data-table-component
      :headers="headers"
      :items="etapas"
      :tiene_paginacion="false"
    >
      <template v-slot:item.periodo="{ item }">
        {{ formatDate(item.fecha_inicio, 'DD/MM/YYYY HH:mm:ss') }}
        -
        {{ formatDate(item.fecha_fin, 'DD/MM/YYYY HH:mm:ss') }}
        <b>({{ item.duracion_en_dias }} dia{{ item.duracion_en_dias > 1 ? 's' : ''}})</b>
      </template>
      <template v-slot:item.cambio_fecha="{ item }">
        <div 
          v-if="!permitirEdicionEtapasFinalizadas && verificarFechaFinEsMenorQueHoy(item)" 
          class="text-subtitle-1 font-weight-bold text-uppercase py-4 d-flex justify-center align-center"
          style="gap: 8px;"
        >
          <v-icon style="font-size: 26px !important;">mdi-calendar-lock</v-icon>
          <span>Etapa finalizada</span>
        </div>
        <div v-else class="d-flex align-center justify-center flex-wrap py-4 cambio-fecha-wrapper" style="gap: 16px;">
          <vc-date-picker
            v-model="item._fecha_inicio_state"
            mode="dateTime"
            locale="es"
            :popover="config_calendar"
            :min-date="hoy?.toDate()"
            :disabled-dates="fechasDeshabilitadas"
            :valid-hours="obtenerHorasValidasInicio(item)"
            @input="(fecha) => manejarCambioFechaInicio(fecha, item)"
          >
            <template v-slot="{ inputEvents, hidePopover, showPopover }">
              <span v-on="inputEvents" @click.stop="showPopover()">
                <v-text-field
                  class="border roundend focus:outline-none focus:border-blue-300"
                  :value="formatearFecha(item._fecha_inicio_state)"
                  @blur="hidePopover()"
                  readonly
                  hide-details
                  outlined
                />
              </span>
            </template>
          </vc-date-picker>

          <vc-date-picker
            v-model="item._fecha_fin_state"
            mode="dateTime"
            locale="es"
            :popover="config_calendar"
            :min-date="obtenerFechaMinimaCierreEtapa(item)"
            :max-date="obtenerFechaMaximaEtapa(item)"
            :disabled-dates="fechasDeshabilitadas"
            :valid-hours="obtenerHorasValidasCierre(item)"
          >
            <template v-slot="{ inputEvents, hidePopover, showPopover }">
              <span v-on="inputEvents" @click.stop="showPopover()">
                <v-text-field
                  class="border roundend focus:outline-none focus:border-blue-300"
                  :value="formatearFecha(item._fecha_fin_state)"
                  :disabled="!item._fecha_inicio_state"
                  @blur="hidePopover()"
                  outlined
                  readonly
                  hide-details
                />
              </span>
            </template>
          </vc-date-picker>

          <v-btn 
            @click.stop="confirmarGuardadoFechas(item)" 
            :disabled="!(item._fecha_inicio_state && item._fecha_fin_state)"
            color="primary"
            x-large
            >
            Actualizar
            <v-icon color="white" class="ml-2" style="font-size: 22px !important;">mdi-send</v-icon>
          </v-btn>
        </div>
      </template>
    </data-table-component>
    <ConfirmationModalComponent
      :is-open="confirmacionCambioEtapa"
      description="¿Desea cambiar las fechas para la etapa seleccionada?"
      :is-loading="false"
      @confirm="cambiarFechasEtapa"
      @cancel="confirmacionCambioEtapa = false"
    />
  </section>
</template>
<script>
import { obtenerNumeroDias, obtenerFechasPermitidas, generarHoras } from "@/utils/dias-habiles";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { ConfirmationModalComponent } from "@/components/utils";
import { formatDate } from '@/utils/datetime';
import { mapActions, mapState } from "vuex";
import moment from "moment";
import { Observable, Listener } from "@/utils/observable";
import { ValidacionAccesos } from "@/utils/role-utils";

export default {
  components: { DataTableComponent, ConfirmationModalComponent },
  props: {
    permitirEdicionFecha: { type: Boolean, default: false },
    permitirEdicionEtapasFinalizadas: { type: Boolean, default: false },
  },
  data: () => ({
    etapas: [],
    etapa: null,
    showModalConfig: false,
    fecha_inicio: null,
    fecha_fin: null,
    config_calendar: {
      // visibility: "click",
      placement: "bottom",
      visibility: 'focus',
    },
    fecha_minima: "",
    fecha_maxima: "",
    id_phase: "",
    phase_name: "",
    horas_disponibles_inicio: [],
    horas_disponibles_fin: [],
    showModal: false,
    showModalEtapa3: false,
    id_etapa: null,
    // Cambio etapa
    etapaActiva: null,
    confirmacionCambioEtapa: false,
    unsubscribe: null,
  }),
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    ...mapState(["asuetos", "feriados", "serverTime"]),
    esUCP() {
      return ValidacionAccesos.ES_UCP;
    },
    esAlMenosUCP() {
      return ValidacionAccesos.ES_AL_MENOS_UCP;
    },
    fechasDeshabilitadas() {            
      const diasFeriados =  this.feriados.map((feriado) => feriado.toDate());

      const diasAsueto = [];
      const anios = 2;
      let anioActual = new Date().getFullYear();

      for (let i = 0; i < anios; i++) {
        this.asuetos.forEach((asueto) => {
          diasAsueto.push(new Date(anioActual, asueto.mes - 1, asueto.dia));
        });
        anioActual++;
      }

      return [{ weekdays: [1, 7] }].concat(diasFeriados).concat(diasAsueto);
    },
    hoy() {
      return this.serverTime.clone();
    },
    headers() {
      const tableHeaders = [
        { text: "Etapa", value: "nombre_etapa", align: "center", sortable: false },
        { text: 'Período', value: 'periodo', align: 'center', sortable: false },
        { text: "Máximo días hábiles", value: "CtlEtapasProcesoImpugnacion.cantidad_dias", align: "center", sortable: false },
      ];

      if (this.permitirEdicionFecha) {
        tableHeaders.push({ text: "Cambiar fecha", value: "cambio_fecha", align: "center", sortable: false });
      }

      return tableHeaders;
    },
  },
  methods: {
    ...mapActions("procesoCompraDoc", ["getEtapasProcesoImpugnacion"]),
    ...mapActions(["cargarDiasNoLaborables"]),
    formatDate,
    async getEtapasImpugnaciones() {
      this.etapas = [];
      this.fechas_etapas = [];
      const response = await this.getEtapasProcesoImpugnacion({
        id_proceso_compra: this.$route.params.idProceso,
        id_etapa_proceso: this.procesoData.id_etapa_pausa,
      });

      this.etapas = response.map((item) => ({
        ...item,
        nombre_etapa: item.CtlEtapasProcesoImpugnacion?.nombre_etapa,
        _fecha_inicio_state: null,
        _fecha_fin_state: null,
      }));
      
    },
    // Validaciones
    formatearFecha(fecha) {
      return fecha
        ? moment(fecha).format("DD/MM/YYYY hh:mm A")
        : "Seleccione una fecha";
    },
    manejarCambioFechaInicio(fecha, item) {
      if (!fecha) return;

      if (moment(fecha).isAfter(moment(item._fecha_fin_state))) {
        item._fecha_fin_state = null;
      }
    },
    obtenerFechaMaximaEtapa(etapa) {
      const fechaInicio = etapa._fecha_inicio_state;
      if (!fechaInicio) return null;

      const fechasPermitidas = obtenerFechasPermitidas(moment(fechaInicio).startOf('day'), etapa.CtlEtapasProcesoImpugnacion.cantidad_dias);

      return fechasPermitidas.length > 0 ? fechasPermitidas.pop().endOf('day').toDate() : null;
    },
    obtenerHorasValidasInicio(etapa) {
      if (!etapa._fecha_inicio_state) return [];

      const fechaInicio = moment(etapa._fecha_inicio_state).clone().startOf('day');
      
      if (this.hoy.clone().startOf('day').isSame(fechaInicio)) {
        return generarHoras(this.hoy.get('hour'));
      }

      return generarHoras(0);
    },
    obtenerHorasValidasCierre(etapa) {
      if (!etapa._fecha_fin_state) return [];

      const fechaInicio = moment(etapa._fecha_inicio_state);
      const fechaFin = moment(etapa._fecha_fin_state);
      
      if (fechaInicio.clone().startOf('day').isSame(fechaFin.clone().startOf('day'))) {
        return generarHoras(fechaInicio.get('hour') + 1);
      }

      return generarHoras(0);
    },
    obtenerFechaMinimaCierreEtapa(etapa) {
      if (!etapa._fecha_inicio_state) return null;

      const fechaInicio = moment(etapa._fecha_inicio_state);

      return fechaInicio.get('hour') < 23 ? etapa._fecha_inicio_state : fechaInicio.clone().add(1, 'day').startOf('day').toDate();
    },
    verificarFechaFinEsMenorQueHoy(etapa) {
      const fechaFin = moment(etapa.fecha_fin);
      return fechaFin.isBefore(this.hoy);
    },
    // Data
    confirmarGuardadoFechas(etapa) {
      // Validaciones
      const fechaInicioEtapa = moment(etapa._fecha_inicio_state);
      const fechaFinEtapa = moment(etapa._fecha_fin_state);

      const diasEnRango = obtenerNumeroDias(fechaInicioEtapa, fechaFinEtapa);
      const diasLimite = etapa.CtlEtapasProcesoImpugnacion.cantidad_dias;

      if (diasEnRango > diasLimite) {
        this.pushAppMessage({ type: 'warning', message: `El máximo de días hábiles para esta etapa es de ${diasLimite} días.` });
        return;
      }

      // Abrir modal
      this.etapaActiva = etapa;
      this.confirmacionCambioEtapa = true;
    },
    async cambiarFechasEtapa() {
      try {
        if (moment(this.etapaActiva._fecha_inicio_state).startOf('minute').isBefore(this.hoy.clone().startOf('minute'))) {
          this.pushAppMessage({ 
            type: "error", 
            message: `El inicio de la etapa no debe ser menor a la fecha/hora actual ${this.hoy.format('DD/MM/YYYY HH:mm')}`
          });
          return;
        }

        const response = await this.services.PacProcesos.updateEtapasImpugnaciones(
          this.$route.params.idProceso,
          this.etapaActiva.id,
          {
            fecha_inicio: moment(this.etapaActiva._fecha_inicio_state).startOf('minute').format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            fecha_fin: moment(this.etapaActiva._fecha_fin_state).startOf('minute').format("YYYY-MM-DD HH:mm:ss"),
          }
        );
  
        if (response.status === 200) {
          this.pushAppMessage({ type: "success", message: "Etapa de proceso actualizada" });
          this.getEtapasImpugnaciones();
          Observable.emit('recargar-etapa-actual-ucp');
        }
      } catch (error) {} 
      finally {
        this.confirmacionCambioEtapa = false;
      }
    },
  },
  created() {
    this.cargarDiasNoLaborables();
    this.getEtapasImpugnaciones();

    const listener = new Listener('EtapasProcesoImpugnacionesComponent').setHandler(() => this.getEtapasImpugnaciones());
    this.unsubscribe = Observable.getInstance().subscribe('recargar-etapas-impugnaciones-ucp', listener);  
  },
  beforeDestroy() {
    this.unsubscribe?.();
  },
};
</script>
<style scoped>
:deep(.cambio-fecha-wrapper > span) {
  flex-grow: 1;
  padding: 0 !important;
}

:deep(.cambio-fecha-wrapper > .v-btn) {
  flex-grow: 2;
}
</style>