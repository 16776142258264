import Vue from "vue";

export class ValidacionAccesos {
    static get ES_PROVEEDOR() {
        return Vue.prototype.haveRoles(['ROLE_PROVEEDOR']);
    }


    static get ES_UCP() {
        return Vue.prototype.haveRoles(["ROLE_UACI", "ROLE_UACI_TECNICO"]);
    }

    static get ES_AL_MENOS_UCP() {
        return Vue.prototype.haveRoles(["ROLE_UACI", "ROLE_UACI_TECNICO", "ROLE_DIRECCION_COMPRAS"]);
    }


    static get ES_TACOP() {
        return Vue.prototype.haveRoles(['ROLE_TACOP']);
    }
}
